<template>
  <div>
    <div class="api-subscription-title has-text-grey-dark has-text-centered">Your Payment Method</div>
    <Spinner v-show="showInProgressSection &&  !showUpdateCreditCardSection" message="Loading your card details. Please wait..."/>
    <DoodleMessage class="has-text-grey-dark" :message="doodleForm.errorMessage" message-class="has-text-danger" v-show="doodleForm.errorMessage" v-if="!showUpdateCreditCardSection"/>
    <div class="has-text-centered" v-if="this.doodleForm.completed && paymentMethodFound">
      <div class="api-subscription-detail">
        <div class="has-text-weight-bold has-text-grey-dark">Card Type</div>
        <div class="has-text-weight-bold has-text-danger">{{this.cardType}}</div>
      </div>
      <div class="api-subscription-detail">
        <div class="has-text-weight-bold has-text-grey-dark">Ends With</div>
        <div class="has-text-weight-bold has-text-danger">{{this.endsWith}}</div>
      </div>
      <div class="api-subscription-detail">
        <div class="has-text-weight-bold has-text-grey-dark">Expires On</div>
        <div class="has-text-weight-bold has-text-danger">{{this.expires}}</div>
      </div>
    </div>
    <div class="has-text-centered has-text-weight-bold" v-if="this.doodleForm.completed && !paymentMethodFound">
      No Credit Card Details Found.
    </div>
    <div class="show-update-credit-card-section has-text-centered" v-show="!showUpdateCreditCardSection">
      <button class="button is-danger has-text-weight-bold" @click="showUpdateSection">Update Credit Card</button>
    </div>
    <div v-if="showUpdateCreditCardSection" class="box has-text-centered">
      <Spinner v-show="showInProgressSection" message="Updating your card details. Please wait..."/>
      <DoodleMessage class="has-text-grey-dark" :message="doodleForm.errorMessage" message-class="has-text-danger" v-show="doodleForm.errorMessage"/>
      <div v-show="!showInProgressSection && !doodleForm.completed">
        <div class="api-payment-box has-text-centered is-centered">
          <div id="apiUpdatePaymentMethod-card-element" class="payment-field has-text-centered">
          </div>
          <img :src="poweredByStripeImage" alt="Powered By Stripe" class="powered-by-stripe"/>
        </div>
        <div class="update-button-section buttons is-centered">
          <button class="button is-danger has-text-weight-bold" @click="updatePaymentMethod">Update</button>
          <button class="button has-text-weight-bold" @click="closeUpdateForm">Close</button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import formMixin from '../../assets/javascript/form-mixin'
import stripeMixin from '../../assets/javascript/stripe-mixin'
import Spinner from '../Utils/Spinner'
import DoodleMessage from '../Utils/DoodleMessage'

export default {
  name: 'apiCardDetails',
  components: { DoodleMessage, Spinner },
  mixins: [formMixin, stripeMixin],
  props: ['tabProperties'],
  data: function () {
    return {
      endsWith: null,
      cardType: null,
      expires: null,
      paymentMethodFound: false,
      showUpdateCreditCardSection: false
    }
  },
  mounted () {
    this.executeAPIWitoutValiation({
      url: '/api/compiler-api/paymentMethod',
      successAction: (data) => {
        this.paymentMethodFound = true
        this.expires = data.expires
        this.cardType = data.cardType
        this.endsWith = data.endsWith
      },
      markCompleted: true,
      form: this.doodleForm,
      jdaCategory: window.jda.CATEGORY.API,
      jdaEvent: 'payment-method',
      jdaLabel: ''
    })
  },
  methods: {
    showUpdateSection () {
      this.showUpdateCreditCardSection = true
      this.doodleForm.completed = false
      this.showUpdateCreditCard()
    },
    closeUpdateForm () {
      this.showUpdateCreditCardSection = false
      this.doodleForm.completed = true
      this.doodleForm.errorMessage = null
    },
    updatePaymentMethod () {
      this.stripe.createToken(this.stripCard).then((result) => {
        if (result.error) {
          this.doodleForm.errorMessage = result.error.message
        } else {
          this.doodleForm.inProgress = true

          this.executeAPIWitoutValiation({
            url: '/api/compiler-api/updatePaymentMethod',
            data: { token: result.token.id },
            successAction: (data) => {
              this.expires = data.expires
              this.cardType = data.cardType
              this.endsWith = data.endsWith
              this.showUpdateCreditCardSection = false
            },
            markCompleted: true,
            form: this.doodleForm,
            jdaCategory: window.jda.CATEGORY.API,
            jdaEvent: 'update-payment-method',
            jdaLabel: ''
          })
        }
      })
    },
    copyToClipboard (text) {
      window.navigator.clipboard.writeText(text).then((e) => {
        this.showCopiedMessage = true
        this.showCopiedMessageText = 'Copied to clipboard'
        window._.delay(() => { this.showCopiedMessage = false }, 1000)
      }, (e) => {
        this.showCopiedMessage = true
        window._.delay(() => { this.showCopiedMessage = false }, 1000)
        this.showCopiedMessageText = 'Copy to clipboard failed'
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .api-subscription-title {
    text-decoration: underline;
    font-weight: bold;
    font-size: 1em;
  }

  .api-subscription-detail {
    margin-top: 1em;
    margin-bottom: 1em;
    font-size: 0.75em;
  }

  .api-subscription-sub-detail {
    font-weight: bold;
    font-size: 0.8em;
  }

  .show-update-credit-card-section {
    margin-top: 40px;
  }

  .update-button-section {
    margin-top:20px;
  }

  #apiUpdatePaymentMethod-card-element {
    max-width: 400px;
    margin: auto;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .powered-by-stripe {
    width: 120px;
  }
</style>
